import React from "react"
import Layout from "../components/layout/layout"
import SEO from "../components/seo"
import TextSection from "../components/text-section/text-section"
import { graphql, Link } from "gatsby"

const NewsPage = props => {
  const news = props.data.news.edges
  const newsIntro = {
    title: "News",
    content:
      "News and press releases for the USLPA",
  }

  return (
    <Layout>
      <SEO title="USLPA - News" />
      <TextSection content={newsIntro} />
      <section className="news-teaser-section">
        <div className="container">
          {news.map((article, index) => (
            <Link to={article.node.path} key={index}><h2>{article.node.title}</h2></Link>
          ))}
        </div>
      </section>
    </Layout>
  )
}

export default NewsPage

export const pageQuery = graphql`
  query {
    news: allNewsJson {
      edges {
        node {
          title
          path
        }
      }
    }
  }
`
